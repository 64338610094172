import React from "react";
import classNames from "classnames";
import { HorizontalScroller } from "../HorizontalScroller";
import styles from "./SmallScreenAddContentToolbar.module.scss";

interface SmallScreenAddContentToolbarProps extends React.HTMLProps<HTMLDivElement> {
    children?: React.ReactNode[];
    hideSidebar?: boolean;
    loaded?: boolean;
}
export const SmallScreenAddContentToolbar = (props: SmallScreenAddContentToolbarProps) => {
    const { children, hideSidebar, ...rest } = props;

    return (
        <div
            {...rest}
            className={classNames(styles.smallscreenAddcontentToolbar, {
                [styles.smallscreenAddcontentToolbarHidden]: hideSidebar
            })}
            data-testid="mobileToolbar"
        >
            <HorizontalScroller isDarkToolbar>
                <div
                    className={classNames(styles.smallscreenAddcontentToolbarButtons)}
                    data-testid="smallScreenToolbar"
                >
                    {children}
                </div>
            </HorizontalScroller>
        </div>
    );
};

SmallScreenAddContentToolbar.displayName = "SmallScreenAddContentToolbar";

import React, { ReactNode } from "react";
import classNames from "classnames";
import styles from "./SmallScreenAddContentToolbarButton.module.scss";

interface SmallScreenAddContentToolbarButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean;
    onClick: () => void;
    dataTestId?: string;
    children: ReactNode | ReactNode[];
}

export const SmallScreenAddContentToolbarButton = (props: SmallScreenAddContentToolbarButtonProps) => {
    const { children, disabled, onClick, dataTestId, ...rest } = props;
    return (
        <button
            {...rest}
            className={classNames(styles.smallscreenAddcontentToolbarButton, {
                [styles.smallscreenAddcontentToolbarButtonDisabled]: disabled
            })}
            onClick={onClick}
            disabled={disabled}
            data-testid={dataTestId}
        >
            {children}
        </button>
    );
};

SmallScreenAddContentToolbarButton.displayName = "SmallScreenAddContentToolbarButton";

import React from "react";
import { H1 } from "@vp/swan";
import { MobileExperience, DesktopExperience } from "@internal/feature-responsive-design";

interface PanelTitleProps extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode;
}

export function PanelTitle({ children, ...rest }: PanelTitleProps) {
    return (
        <>
            <MobileExperience>
                <H1 fontSize="standard" fontWeight="bold" my={0} pb={"3"} {...rest}>
                    {children}
                </H1>
            </MobileExperience>
            <DesktopExperience>
                <H1 fontSize="large" fontWeight="bold" my={0} pb={"4"} {...rest}>
                    {children}
                </H1>
            </DesktopExperience>
        </>
    );
}
PanelTitle.displayName = "PanelTitle";

import React, { type JSX } from "react";
import { ToolbarButton } from "./ToolbarButton";
import styles from "./SmallScreenButton.module.scss";
import { SimpleButtonProps } from "./types";

interface SmallScreenButtonProps extends SimpleButtonProps {
    icon: JSX.Element | null;
    label: string;
    showLabel?: boolean;
}

export const SmallScreenButton = (props: SmallScreenButtonProps) => {
    const { icon, label, showLabel = true, ...rest } = props;

    return (
        <ToolbarButton
            title={!showLabel ? label : ""}
            aria-label={!showLabel ? label : ""}
            className={styles.smallScreenToolbarButton}
            {...rest}
        >
            {icon}
            {showLabel && <label>{label}</label>}
        </ToolbarButton>
    );
};

SmallScreenButton.displayName = "SmallScreenButton";

import React from "react";

export function RectangleIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <rect x="5" y="8" width="16" height="10" stroke="currentColor" strokeWidth="2" />
        </svg>
    );
}
RectangleIcon.displayName = "RectangleIcon";

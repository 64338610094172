import React from "react";

export function EditTableIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 32 32" fill="none" {...props}>
            <rect x="7" y="7" width="18" height="18" stroke="currentColor" strokeWidth={2} />
            <rect x="15" y="6" width="2" height="20" fill="currentColor" />
            <rect x="26" y="3" width="2" height="20" transform="rotate(90 20 9)" fill="currentColor" />
        </svg>
    );
}
EditTableIcon.displayName = "EditTableIcon";

import React from "react";
import { P, PProps } from "@vp/swan";

export const PanelDescription = (props: PProps) => {
    const { children, ...rest } = props;
    return (
        <P fontSize="small" textColor="subtle" my={0} {...rest}>
            {children}
        </P>
    );
};
PanelDescription.displayName = "PanelDescription";

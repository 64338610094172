import React from "react";

export function UploadedImageIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg viewBox="0 0 20 20" fill="none" {...props}>
            <rect
                x="1"
                y="1"
                width="18"
                height="18"
                fill="none"
                rx="3"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g transform="translate(2, 2)">
                <g transform="scale(0.8)">
                    <path
                        d="M 2.743 16.999 C 6 17 12.6962 17.0028 17.135 17"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />

                    <path d="M10 3L10.0015 13.2122" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                    <path
                        d="M6.82031 6.47656L10.0003 2.99831L13.1803 6.47656"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
}
UploadedImageIcon.displayName = "UploadedImageIcon";
